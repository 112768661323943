(($) => {
    $(document).ready(() => {

        if ($('.products-container').length > 0) {

            let $_company = $('.btn-products-company');
            let $_shop = $('.btn-products-shop');

            let swiper = new Swiper('.products-container', {
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    init: function () {
                        $_company.addClass('btn-primary');
                        $_company.removeClass('btn-outline-primary');
                    },
                    slideChange: function () {
                        $_company.removeClass('btn-outline-primary btn-primary');
                        $_shop.removeClass('btn-outline-primary btn-primary');
                        if (this.activeIndex === 0) {
                            $_company.addClass('btn-primary');
                            $_shop.addClass('btn-outline-primary');
                        } else {
                            $_company.addClass('btn-outline-primary');
                            $_shop.addClass('btn-primary');
                        }
                    }
                }
            });

            $_company.on('click', function () {
                swiper.slideTo(0);
            });

            $_shop.on('click', function () {
                swiper.slideTo(1);
            });
        }
    });
})(jQuery);